import React, { useState } from "react";
import LogoIcon from "../../asset/logo-icon.svg";
import LogoText from "../../asset/logo-text.svg";
import Burger from "../../asset/burgermenu.svg";

import useWindowDimensions from "../../hooks/useGetSize.js";

import { Menu } from "../Menu";
import { ModalFullScreenMobile } from "../ModalFullScreenMobile/ModalFullScreenMobile";
import { Link } from "react-router-dom";

const col1 = [
  { menu: "Links", anchorId: "footer", isTitle: true },
  { menu: "Home", anchorId: "hero", isTitle: false },
  { menu: "Our Spcialty", anchorId: "specialty", isTitle: false },
  // { menu: "Testimonials", anchorId: "testimonial", isTitle: false },
  { menu: "Contact Us", anchorId: "contact", isTitle: false },
];
const col2 = [
  { menu: "Our Products", anchorId: "products", isTitle: true },

  { menu: "ReMoCapp", anchorId: "remoc", isTitle: false },
  { menu: "FlexUI", anchorId: "flexUI", isTitle: false },
  { menu: "Wear Land", anchorId: "wearland", isTitle: false },
  { menu: "Exchange Service", anchorId: "exchange", isTitle: false },
  { menu: "iCerts", anchorId: "icert", isTitle: false },
  { menu: "RaadCoin", anchorId: "raadcoin", isTitle: false },
];

const col3 = [
  { menu: "clients’ Projects", anchorId: "client", isTitle: true },
  { menu: "Bountye", anchorId: "bounty", isTitle: false },
  { menu: "Humm", anchorId: "humm", isTitle: false },
  { menu: "Catalyser", anchorId: "catalyser", isTitle: false },
  { menu: "Safe-T Device", anchorId: "SafeTDevice", isTitle: false },
  { menu: "FuPay", anchorId: "fupay", isTitle: false },
  { menu: "My Amex Shopping", anchorId: "amex", isTitle: false },
  { menu: "Belz Family", anchorId: "belz", isTitle: false },
  { menu: "Easy Payslip", anchorId: "easy", isTitle: false },
];

const Header = () => {
  const [isShowModal, setIsShowModal] = useState(false);
  const { height } = useWindowDimensions();

  const handleClick = () => {
    const anchor = document.querySelector(`#contact`);
    anchor?.scrollIntoView({ behavior: "smooth", block: "center" });
  };

  const handleShowModal = () => {
    setIsShowModal(!isShowModal);
  };
  return (
    <div className="flex justify-center z-[99999999999] ">
      <header
        className={` z-[99999999999]  mx-auto md:p-3    flex  h-[56px]   max-w-[330px] md:max-w-[680px]  lg:max-w-[960px]   xl:max-w-[1424px]  md:mt-[24px]    justify-between w-full items-center     ${
          height > 80
            ? " mt-[17px]  fixed z-[99999999999] top-[-24px] "
            : " mt-[40px]  absolute  "
        }   `}
      >
        <Link
          to="/"
          className={`${
            height > 80 ? "opacity-0  " : "flex items-center gap-[6px]"
          } `}
        >
          <img className=" nonselect" src={LogoIcon} alt="raadco-logo" />
          <img
            className="hidden nonselect md:flex"
            src={LogoText}
            alt="raadco-logo"
          />
        </Link>
        <div className="flex items-center gap-[29px]">
          <button
            onClick={handleClick}
            href="#contact"
            className={`${
              height > 80 ? "opacity-0  " : "hidden contact md:flex xl:flex"
            } `}
            // className="hidden contact md:flex xl:flex"
          >
            Contact us
          </button>

          <button
            className="flex lg:menu-d md:menu-t menu-m"
            onClick={handleShowModal}
          >
            <img src={Burger} alt="hamburgermenu" />
          </button>
        </div>

        <ModalFullScreenMobile
          showModal={isShowModal}
          children={
            <Menu
              isShowModal={isShowModal}
              setIsShowModal={setIsShowModal}
              col1={col1}
              col2={col2}
              col3={col3}
              handleCloseModal={handleShowModal}
            />
          }
        />
      </header>
    </div>
  );
};
export default Header;
