import React from "react";

import { BelzLogo } from "../../asset/belz.js";
import BelzBG from "../../asset/belz-bg.webp";
import BelzLight from "../../asset/belz-light.svg";

import "./belz.css";

const Belz = () => {
  return (
    <section
      id="belz"
      className=" flex   xl:bg-height lg:bg-height-lg  bg-[#D2D2D2]   relative w-full lg:max-w-[960px]    lg:mb-[33px] xl:mb-[64px]     min-h-[855px]  justify-center  items-center xl:items-start     xl:justify-end     xl:max-w-[1424px] mx-auto"
    >
      <img
        src={BelzLight}
        alt="bg-light-up"
        className="absolute z-0 hidden xl:flex "
      />
      <div className=" relative xl:mt-[20vh]   gap-y-[8vh]   xl:mr-[30px]    space-y-[1.8vh]   flex flex-col-reverse xl:flex-col        xl:max-w-[1302px]       ">
        <img
          src={BelzLight}
          alt="bg-light"
          className="absolute z-0 object-cover  sm:h-[800px]     md:h-[700px]    xl:hidden"
        />
        <div className="flex xl:absolute xl:left-[-900px]           ">
          <img
            src={BelzBG}
            alt="belz-bg"
            className="md:w-full md:h-full flex object-cover  object-left     sm:h-[38vh]    xl:max-h-[586px] z-10 "
          />
        </div>

        <div className=" z-10  flex flex-col items-center justify-center  xl:items-start      space-y-[1.8vh]">
          <BelzLogo
            color="#C8895B"
            className=" mt-[80px]    w-[197px] h-[56px]  lg:w-[256px] lg:h-[72px] md:w-[235px] md:h-[67px]   xl:w-[301px] xl:h-[95px] "
          />
          <h2
            style={{ color: "#1F4653" }}
            className=" xl:max-w-[546px] lg:max-w-[636px] md:max-w-[509px] max-w-[353px]
    xl:wear-desc-d md:wear-desc-t   wear-desc-m xl:text-left"
          >
            Belzfamily is an investment company that manages a community of
            primarily Chinese investors who enter various projects. We designed
            a website and portal for this group so that they could better manage
            the members of the group.
          </h2>
          <div className="flex flex-wrap items-center justify-center xl:justify-start     gap-[16px]  sm:max-w-[228px]       md:max-w-[560px]">
            <div className=" belz-tag-m xl:belz-tag-d md:belz-tag-t">
              Web & Portal
            </div>
            <div className="belz-tag-m xl:belz-tag-d md:belz-tag-t">
              Dashboard
            </div>
            <div className="belz-tag-m xl:belz-tag-d md:belz-tag-t"> SEO </div>
            <div className="belz-tag-m xl:belz-tag-d md:belz-tag-t"> Blog </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Belz;
