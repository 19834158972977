export const BelzLogo = ({ color, className }) => {
  return (
    <svg
      aria-label="belz"
      className={className}
      width="200"
      height="59"
      viewBox="0 0 200 59"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
    >
      <path
        d="M77.8183 15C82.7634 15 86.8756 17.494 86.8756 22.888C86.8756 25.266 85.8866 27.528 83.4401 28.804C86.7715 30.138 88.1249 33.85 87.5523 37.214C86.7715 41.912 83.2839 44 78.0786 44C73.8623 44 69.2816 44 65.0132 44V15H77.8183ZM70.687 26.252H77.7663C80.0566 26.252 81.1497 25.208 81.1497 23.526C81.1497 21.844 80.0566 20.568 77.8183 20.568H70.687V26.252ZM70.687 38.374H77.8183C80.421 38.374 81.6703 37.098 81.6703 35.068C81.6703 33.038 80.3169 31.646 77.7663 31.646C75.4759 31.646 73.0294 31.646 70.687 31.646V38.374Z"
        fill={color}
      />
      <path
        d="M125.968 44H106.135C106.135 34.372 106.135 24.628 106.135 15H125.447V20.916H111.861V26.484H124.666V32.168H111.861V38.026H125.968V44Z"
        fill={color}
      />
      <path
        d="M150.485 15V38.084H163.498V44H144.707V15H150.485Z"
        fill={color}
      />
      <path
        d="M179.53 15H199.727V17.842L186.922 37.968H199.727V44H178.906V40.636L191.607 20.568H179.53V15Z"
        fill={color}
      />
      <path
        d="M18.5816 0.79C26.9101 0.79 30.0333 7.17 30.0333 15.232C30.0333 23.526 27.0663 29.384 18.2692 29.384C17.8528 29.384 17.4884 29.384 17.072 29.384V29.616H19.8829C28.5758 29.616 32.5319 35.822 32.5319 43.884C32.5319 52.178 28.784 58.326 19.6226 58.326H14.3652V58.558H20.5075C30.9182 58.558 38.9865 52.41 38.9865 43.652C38.9865 35.3 32.7921 29.732 22.5897 29.384C30.8141 28.224 36.4879 22.772 36.4879 14.942C36.4879 6.242 30.6059 0.5 20.1952 0.5H14.3652V0.732"
        fill={color}
      />
      <path
        d="M0.935264 52.4102H0.727051V58.2682V58.5002H0.935264H6.19265V58.2682C3.27767 58.2682 0.935264 55.6582 0.935264 52.4102Z"
        fill={color}
      />
      <path
        d="M0.935264 0.5H0.727051V0.732V6.59H0.935264C0.935264 3.342 3.27767 0.732 6.19265 0.732V0.5H0.935264Z"
        fill={color}
      />
    </svg>
  );
};
