import { useEffect, useRef, useState } from "react";
import { NavLink } from "react-router-dom";
import "./menu.scss";
import CloseSvg from "../../asset/close.svg";
import { social } from "../Footer/data";

export const Menu = ({
  className,
  col1,
  col2,
  col3,
  handleCloseModal,
  isShowModal,
  setIsShowModal,
}) => {
  const [isIndex, setIsIndex] = useState(0);
  const wrapperRef = useRef(null);
  const useOutsideAlerter = (ref) => {
    useEffect(() => {
      /**
       * Alert if clicked on outside of element
       */
      function handleClickOutside(event) {
        if (ref.current && !ref.current.contains(event.target)) {
          isShowModal && handleCloseModal();
          // isShowModal && setIsShowModal(isShowModal);
        }
      }
      // Bind the event listener
      document.addEventListener("mousedown", handleClickOutside);
      return () => {
        // Unbind the event listener on clean up
        document.removeEventListener("mousedown", handleClickOutside);
      };
    }, [ref, isShowModal]);
  };
  useOutsideAlerter(wrapperRef);

  const handleClick = (anchorId, index) => {
    const anchor = document.querySelector(`#${anchorId}`);
    anchor?.scrollIntoView({ behavior: "smooth", block: "start" });
    handleCloseModal && handleCloseModal();
    setIsIndex(anchorId);
  };

  return (
    <div
      ref={wrapperRef}
      className={`rhMenuWrapper ${className} flex flex-row  justify-between `}
    >
      <div className="flex flex-row-reverse mx-auto sm:max-w-[330px] md:max-w-[800px]     justify-between w-full ">
        {handleCloseModal && (
          <div>
            <div
              className="header  w-[56px]  h-[56px] cursor-pointer "
              onClick={handleCloseModal}
            >
              <img
                className="sm:w-[16px] sm:h-[19px]  "
                src={CloseSvg}
                alt="close"
              />
            </div>
            <div className="flex flex-col items-center gap-[30px] mt-[47px] lg:hidden ">
              {social.map((item) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  key={item.name}
                  href={item.href}
                  className="text-[#433CAE] hover:text-gray-500"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon className="w-6 h-6 " aria-hidden="true" />
                </a>
              ))}
            </div>
          </div>
        )}
        <nav
          className={`rhMenu space-y-[20%] lg:mt-[32px]    relative lg:gap-x-[64px]     lg:grid  lg:grid-cols-3  lg:space-y-0  whitespace-nowrap     `}
        >
          <div className=" flex flex-col space-y-[8px] ">
            {col1?.map((m, index) => (
              <div key={index}>
                <NavLink
                  key={index}
                  to={`/${m.link ?? ""}`}
                  className={`${
                    m.anchorId === "contact"
                      ? "hide-in-desktop"
                      : m?.isTitle
                      ? "title"
                      : isIndex === m?.anchorId
                      ? `activeClassName  ${m.class}`
                      : m.class
                  }`}
                  onClick={() => handleClick(m.anchorId)}
                >
                  {m.menu}
                </NavLink>
              </div>
            ))}

            <div className="flex flex-col lg:pt-[88px] items-center  justify-center space-y-[25px] ">
              <div className="hidden gap-x-[25px]  lg:flex">
                {social.map((item) => (
                  <div
                    target="_blank"
                    rel="noreferrer"
                    key={item.name}
                    href={item.href}
                    style={{ color: "#433CAE" }}
                    className="flex hover:text-gray-500"
                  >
                    <span className="sr-only">{item.name}</span>
                    <item.icon className="w-6 h-6 " />
                  </div>
                ))}
              </div>
              <div className="hidden w-full lg:flex ">
                <button
                  style={{ borderRadius: 0, width: "100%" }}
                  className=" contact"
                  onClick={() => handleClick("contact")}
                >
                  Contact us
                </button>
              </div>
            </div>
          </div>
          <div className="  flex flex-col space-y-[8px] ">
            {col2?.map((m, index) => (
              <NavLink
                key={index}
                to={`/${m.link ?? ""}`}
                className={`${
                  m?.isTitle
                    ? "title"
                    : isIndex === m?.anchorId
                    ? `activeClassName  ${m.class}`
                    : m.class
                }`}
                onClick={() => handleClick(m.anchorId)}
              >
                {m.menu}
              </NavLink>
            ))}
          </div>
          <div className=" flex flex-col space-y-[8px] ">
            {col3?.map((m, index) => (
              <NavLink
                key={index}
                to={`/${m.link ?? ""}`}
                className={`${
                  m?.isTitle
                    ? "title"
                    : isIndex === m?.anchorId
                    ? `activeClassName  ${m.class}`
                    : m.class
                }`}
                onClick={() => handleClick(m.anchorId)}
              >
                {m.menu}
              </NavLink>
            ))}
          </div>
        </nav>
      </div>
    </div>
  );
};
