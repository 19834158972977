import React from "react";
import ExchangeLogo from "../../asset/exchange-logo.svg";
import Mockup from "../../asset/excMock.webp";
//test
// import Shape from "../../asset/excShape-md.svg";

const Exchange = () => {
  return (
    <section id="exchange" className="min-h-screen exh-bg ">
      <div className="relative flex flex-col items-center justify-center min-h-screen exh-bg-d xl:flex-row xl:justify-center">
        <div className="z-10 flex flex-col items-center xl:flex-col xl:justify-start xl:items-start xl:text-left">
          <img
            src={ExchangeLogo}
            className=" p-1 sm:p-0 xl:mt-[116px] xl:mb-[8px]   md:mt-[72px] md:mb-[40px]   mt-[88px] mb-[40px]    w-[336px] h-[36px]  md:w-[448px] md:h-[48px]   xl:w-[570px] xl:h-[62px] "
            alt="wearland"
          />

          <h2
            style={{ marginBottom: "40px" }}
            className="  max-w-[353px]   xl:max-w-[583px]   md:max-w-[617px]  xl:wear-desc-d md:wear-desc-t wear-desc-m xl:text-left "
          >
            We have implemented a B2B restful API that converts $ to Bitcoin and
            vice-versa. This service is bound to some third-party services and
            helps them to have an in-app exchange service without any Web3
            knowledge.
          </h2>

          <div className="flex  flex-wrap mb-[32px]  md:mb-[90px]    xl:mb-[48px] items-center justify-center gap-[16px]  max-w-[330px] md:max-w-[330px] xl:max-w-[560px]  ">
            <div className="tag-m xl:tag-d md:tag-t"> Web3 </div>
            <div className="tag-m xl:tag-d md:tag-t"> Exchange </div>
            <div className="tag-m xl:tag-d md:tag-t"> SDK </div>
          </div>
        </div>

        <img
          src={Mockup}
          className=" sm:max-w-[100%]   xl:max-w-[760px]   md:max-w-[574px] md:h-[649px]   "
          alt="mockup"
        />
      </div>
    </section>
  );
};

export default Exchange;
