import Hero from "../../components/Hero";
import Feature from "../../components/Feature";
import Products from "../../components/Products";
import Wear from "../../components/Wear";
import Exchange from "../../components/Exchange";
import Icert from "../../components/Icert";
import RaadCoin from "../../components/Raadcoin";
import Clients from "../../components/Clients";
import Bounty from "../../components/Bounty";
import Humm from "../../components/Humm";
import Fuplace from "../../components/Fuplace";
import Belz from "../../components/Belz";
import Express from "../../components/Express";
import Easy from "../../components/Easy";
// import Testimonials from "../../components/Testimonials";
import Contact from "../../components/Contact";

import React from "react";

import "./home.css";
import Remocapp from "../../components/Remocapp";
import FlexUI from "../../components/FlexUI";
import Catalyser from "../../components/Catalyser";
import SafeTDevice from "../../components/SafeTDevice";

const Home = () => {
  return (
    <main className="layer">
      <Hero />
      <Feature />
      <Products />
      <Remocapp/>
      <FlexUI/>
      <Wear />
      <Exchange />
      <Icert />
      <RaadCoin />
      <Clients />
      <Bounty />
      <Humm />
      <Catalyser/>
      <SafeTDevice/>
      <Fuplace />
      <Belz />
      <Express />
      <Easy />
      {/* <Testimonials /> */}
      <Contact />
    </main>
  );
};

export default Home;
