import React from "react";

import Up from "../../asset/arrow-up.svg";

const ScrollButton = (props) => {
  // const [visible, setVisible] = useState(false);

  // const toggleVisible = () => {
  //   const scrolled = document.documentElement.scrollTop;
  //   if (scrolled > 300) {
  //     setVisible(true);
  //   } else if (scrolled <= 300) {
  //     setVisible(false);
  //   }
  // };

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: "smooth",
      /* you can also use 'auto' behaviour
         in place of 'smooth' */
    });
  };

  // window.addEventListener("scroll", toggleVisible);

  return (
    <button
      {...props}
      onClick={() => {
        scrollToTop();
      }}
      className=" w-[72px] h-[72px] bg-white rounded-full flex items-center justify-center  "
    >
      <img src={Up} alt="scroll to top" className="w-[24px] h-[24px]  " />
    </button>
  );
};

export default ScrollButton;
