import React from "react";
import Tablet from "../../asset/Tablet.webp";
import logo from "../../asset/BountyeLogo.svg";
import Shape from "../../asset/bounty-bg.svg";

const Bounty = () => {
  return (
    <section
      id="bounty"
      className="flex py-[20px] xl:bg-height      relative lg:mb-[33px] xl:mb-[64px]  xl:max-w-[1424px] mx-auto  lg:max-w-[960px] xl:flex-col    flex-col-reverse items-center justify-around   bounty-bg "
    >
      <img
        src={Shape}
        alt="bounty"
        className="absolute top-0 xl:left-[28px] xl:top-[-30px]   lg:left-[58px] md:left-[55px]  md:w-[313px] md:h-[263px]  sm:w-[287px] sm:h-[241px]  sm:left-[3px]  xl:w-[550px] xl:h-[461px]    "
      />
      <div className="flex justify-center">
        <img
          src={Tablet}
          alt="tablet"
          className="mb-[60px] xl:mb-0  md:w-full md:h-fulls flex object-cover  object-right      sm:h-[50vh]   "
        />
      </div>
      <div className="flex flex-col items-center justify-around xl:flex-row xl:items-start xl:justify-between  xl:max-w-[1302px] mx-auto xl:gap-[47px] gap-y-4  ">
        <img
          src={logo}
          className="w-[197px] h-[56px] sm:mt-[80px] xl:mt-0       lg:w-[256px] lg:h-[72px] md:w-[235px] md:h-[67px]   xl:w-[256px] xl:h-[72px] "
          alt="bounty"
        />

        <h2
          className="xl:max-w-[546px] lg:max-w-[847px] md:max-w-[594px] max-w-[353px]
    xl:wear-desc-d md:wear-desc-t wear-desc-m xl:text-left"
        >
          Bountye SaaS solution is one of the projects we have created and
          maintained since over five years ago. This project is a product search
          engine that gathers all products from retailers, recategorises and
          finds a perfect affiliate program suitable for each product. It lets
          users find anything they need. This project was upgraded 5 times, each
          version with new features.
        </h2>

        <div className="flex flex-wrap xl:justify-start    items-center justify-center gap-[16px]  max-w-[330px] md:max-w-[330px] xl:max-w-[560px]">
          <div className="tag-m xl:tag-d md:tag-t"> Search engine </div>
          <div className="tag-m xl:tag-d md:tag-t"> Crowler </div>
          <div className="tag-m xl:tag-d md:tag-t"> Dashboard </div>
          <div className="tag-m xl:tag-d md:tag-t"> AI </div>
          <div className="tag-m xl:tag-d md:tag-t"> Cashback </div>
          <div className="tag-m xl:tag-d md:tag-t"> SDK </div>
          <div className="tag-m xl:tag-d md:tag-t"> AWS </div>
        </div>
      </div>
    </section>
  );
};

export default Bounty;
