import React from "react";
import flexUI from "../../asset/flexUI.svg";
import flexuiBack from "../../asset/FlexUlBack.png";
import FlexUIBgSm from "../../asset/FlexUIBgSm.png";

// import Shape from "../../asset/excShape-md.svg";
import "./style.css";
import useWindowDimensions from "../../hooks/useGetSize";

const FlexUI = () => {
  const { width } = useWindowDimensions();
  const badges = [
    "Design System",
    "+310 UI Kit & Page Template",
    "+1000 Component and Variant",
    "6 Ready Variable Mode",
    "+390 Variables and Styles",
  ];

  return (
    <section
      id="flexUI"
      className="mi overflow-hidden sm:pt-[67px]  xl:py-[240px]  "
    >
      <div className="   relative flex flex-col items-center justify-center xl:flex-row xl:justify-center lg:flex-col">
        <div className={`  w-full text-[#221E57] xl:px-16  z-10 flex flex-col ${(width<1540 && width>1440) ? "items-start px-8" :'items-center'} xl:flex-col lg:justify-start xl:items-start xl:text-left xl:translate-x-40` }>
          <img
            src={flexUI}
            className=" p-1 px-10 sm:p-0     mb-[40px] xl:text-left      lg:max-w-[436px] h-[36px]  md:max-w-[448px] md:h-[48px]   xl:max-w-[300px] xl:h-[62px] "
            alt="flexUI"
          />

          <p className="!text-[#221E57]  lg:max-w-[704px]  mb-[40px] sm:max-w-[704px] text-center md:px-0 sm:px-5 md:text-[21px] text-[14px] sm:text-[14px] font-medium    xl:text-left  ">
            FlexUI is a Flexible and variable design system that emanates
            sophistication, ingenuity, and exploration as a partner in your
            design projects, this system is extremely flexible, based on
            variables that can be tailored to meet your every need in the world
            of web design. With FlexUI, you can effortlessly create lifelike
            projects while enjoying the speed and professionalism of designing
            robust, commanding, and awe-inspiring designs.
          </p>

          <div className="flex   flex-wrap mb-[32px]  md:mb-[90px]    xl:mb-[48px] items-center lg:justify-start justify-center gap-[16px] max-w-[704px] ">
            {badges.map((item, index) => (
              <span
                key={index}
                className="tag-m xl:tag-d md:tag-t !text-[#6236F5]  font-medium !bg-[#EFEBFE]"
              >
                {item}
              </span>
            ))}
          </div>
        </div>
      
        {width > 1440 ? (
          <img
            className="w-[1714px] h-[1510px] left-[730px] top-[-345px] absolute"
            src={flexuiBack}
          />
        ) : width < 1440 && width > 1024 ? (
          <div className="max-h-[650px] overflow-hidden">
            <img
              className="w-[1714px] object-contain -translate-y-40 translate-x-0 scale-110 h-[1278px] left-[-101px] top-[558px] "
              src={flexuiBack}
            />
          </div>
        ) : width > 767 && width < 1024 ? (
          <div className="max-h-[650px] overflow-hidden">
            <img
              className="w-[1714px] object-contain -translate-y-[240px] -translate-x-5 scale-125 h-[1278px] "
              src={flexuiBack}
            />
          </div>
        ) : (
          <div className="max-h-[650px] overflow-hidden scale-100">
            <img
              className="w-[1714px] object-contain -translate-y-[340px] scale-[145%] -translate-x-0  h-[1278px] "
              src={flexuiBack}
            />
          </div>
        )}

        {/* <div className="flex-1 w-1/2 overflow-hidden max-h[400px]">

        <img
            src={flexuiBack}
            // className="absolute -top-[180px] scale-[85%] -right-[380px]"
            // className="w-auto h-auto xl:translate-x-32 lg:translate-x-0 md:translate-x-9 md:translate-y-16"
            alt="mockup"
          />
    </div> */}

        {/* <div 
        className="flex-1"
        // className="xl:flex-[1.45]  object-cover xl:scale-125 lg:scale-100 md:scale-[125%] overflow-hidden xl:max-h-min lg:max-h-[600px] max-h-[500px] sm:scale-150 md:translate-y-0  sm:translate-y-40 "
        >
       
          <img
            src={flexuiBack}
            className="scale-[155%] translate-x-56 translate-y-32"
            // className="w-auto h-auto xl:translate-x-32 lg:translate-x-0 md:translate-x-9 md:translate-y-16"
            alt="mockup"
          />
        </div> */}
      </div>
    </section>
    //     <div className="w-[1920px] h-[960px] relative bg-stone-50">
    //     <img className="w-[1714px] h-[1510px] left-[730px] top-[-115px] absolute" src={flexuiBack} />
    //     <div className="left-[151px] top-[239px] absolute flex-col justify-start items-start gap-10 inline-flex">
    //         <div className="justify-start items-end gap-4 inline-flex">
    //             <div className="justify-start items-center gap-[24.85px] flex">
    //                 <div className="justify-start items-center gap-[45px] flex">
    //                     <div className="flex-col justify-start items-center inline-flex" />
    //                     <div className="w-[227.81px] h-[90px] text-violet-600 text-[84.38px] font-normal font-['Google Sans'] leading-[169.39px]">FlexUI</div>
    //                 </div>
    //             </div>
    //         </div>
    //         <div className="w-[704px] text-indigo-950 text-[21px] font-medium font-['Montserrat'] leading-[32.90px]">FlexUI is a Flexible and variable design system that emanates sophistication, ingenuity, and exploration as a partner in your design projects, this system is extremely flexible, based on variables that can be tailored to meet your every need in the world of web design. With FlexUI, you can effortlessly create lifelike projects while enjoying the speed and professionalism of designing robust, commanding, and awe-inspiring designs.</div>
    //         <div className="self-stretch justify-start items-start gap-4 inline-flex">
    //             <div className="w-[120px] h-8 px-4 py-2.5 bg-violet-100 justify-center items-center gap-2.5 flex">
    //                 <div className="text-center text-violet-600 text-xs font-medium font-['Montserrat'] leading-[50.40px]">Design System</div>
    //             </div>
    //             <div className="w-[171px] px-4 py-2.5 bg-violet-100 justify-center items-center gap-2.5 flex">
    //                 <div className="text-center text-violet-600 text-xs font-medium font-['Montserrat'] leading-[50.40px]">6 Ready Variable Mode</div>
    //             </div>
    //             <div className="w-[202px] px-4 py-2.5 bg-violet-100 justify-center items-center gap-2.5 flex">
    //                 <div className="text-center text-violet-600 text-xs font-medium font-['Montserrat'] leading-[50.40px]">+310 UI Kit & Page Template</div>
    //             </div>
    //             <div className="w-[186px] px-4 py-2.5 bg-violet-100 justify-center items-center gap-2.5 flex">
    //                 <div className="text-center text-violet-600 text-xs font-medium font-['Montserrat'] leading-[50.40px]">+390 Variables and Styles</div>
    //             </div>
    //             <div className="w-[225px] px-4 py-2.5 bg-violet-100 justify-center items-center gap-2.5 flex">
    //                 <div className="text-center text-violet-600 text-xs font-medium font-['Montserrat'] leading-[50.40px]">+1000 Component and Variante</div>
    //             </div>
    //         </div>
    //     </div>
    // </div>
  );
};

export default FlexUI;
