import React from "react";

import EasyLogo from "../../asset/easy.svg";
import EasyImage from "../../asset/Easy-image.webp";
import Circle from "../../asset/circle.svg";

const Easy = () => {
  return (
    <section
      id="easy"
      className="  lg:mb-[33px]    relative flex xl:flex-row lg:max-w-[960px] flex-col  items-center    xl:bg-height     xl:max-w-[1424px] mx-auto  easy-bg"
    >
      <div className=" flex xl:flex-row  justify-center flex-col   lg:mb-[33px] xl:mb-[64px]  items-center lg:max-w-[960px]      gap-y-[3vh]   xl:max-w-[1202px] mx-auto ">
        <div className="flex  mt-[80px] gap-y-6 flex-col items-center justify-around  xl:items-start xl:justify-start  xl:max-w-[1302px] mx-auto    max-h-[546px]    ">
          <img
            src={EasyLogo}
            className="w-[197px] h-[56px]  lg:w-[256px] lg:h-[72px] md:w-[235px] md:h-[67px]   xl:w-[230px] xl:h-[111px] "
            alt="EasyPay"
          />

          <h2
            className="xl:max-w-[546px] lg:max-w-[546px] md:max-w-[538px] max-w-[353px]
    xl:wear-desc-d md:wear-desc-t wear-desc-m xl:text-left"
          >
            Create payslips in minutes, automatically emailed to your employees
            and backed up in the app. No more time spent manually calculating
            tax and super when you run payroll.
            <br /> Multiple rates, overtime, commissions, deductions,
            allowances.
          </h2>

          <div className="flex flex-wrap items-center justify-center xl:justify-start     gap-[16px]  max-w-[330px] md:max-w-[500px]   lg:max-w-[509px] xl:max-w-[560px]">
            <div className="tag-m xl:tag-d md:tag-t"> Web</div>
            <div className="tag-m xl:tag-d md:tag-t"> Android </div>
            <div className="tag-m xl:tag-d md:tag-t"> IOS </div>
            <div className="tag-m xl:tag-d md:tag-t"> Accounting </div>
            <div className="tag-m xl:tag-d md:tag-t"> dashboard </div>
          </div>
        </div>

        <div className="flex  xl:mt-[250px]   ">
          <img src={EasyImage} alt="background" />
        </div>

        <img
          src={Circle}
          alt="shape"
          className="absolute bottom-0 left-0 z-30 "
        />
      </div>
    </section>
  );
};

export default Easy;
