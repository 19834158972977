import React from "react";
import FuplaceLogo from "../../asset/fuplace-white.svg";
import Iphone from "../../asset/fu-iPhone.webp";
import "./fuplace.css";
const Fuplace = () => {
  return (
    <section
      id="fupay"
      className="flex relative w-full lg:max-w-[960px] items-center xl:items-start    lg:mb-[33px] xl:mb-[64px]     min-h-[800px]  justify-center    xl:justify-end  xl:bg-height   xl:max-w-[1424px] mx-auto fu-bg   "
    >
      <div className=" relative mt-[10vh]     gap-y-[8vh]   xl:mr-[30px]    space-y-[1.8vh]   flex flex-col-reverse xl:flex-col         xl:max-w-[1302px]       ">
        <div className="xl:absolute flex   xl:left-[-850px]  xl:top-[120px]    ">
          <img
            src={Iphone}
            alt="background"
            className="w-auto h-auto  xl:max-w-[975px] xl:max-h-[532px]    "
          />
        </div>

        <div className=" flex    flex-col items-center justify-center  xl:items-start      space-y-[1.8vh]">
          <img
            src={FuplaceLogo}
            className="w-[197px]   h-[56px]  lg:w-[256px] lg:h-[72px] md:w-[235px] md:h-[67px]   xl:w-[201px] xl:h-[72px] "
            alt="fuplacelogo"
          />

          <h2
            className="    xl:max-w-[546px] lg:max-w-[636px] md:max-w-[594px] max-w-[353px]
    xl:wear-desc-d md:wear-desc-t wear-desc-m xl:text-left"
          >
            Fupay is another BNPL company that we implemented their marketplace
            and is powered by Bountye SaaS solution. This marketplace is
            integrated with their mobile application through native codes and
            Web-View.
          </h2>

          <div className="flex flex-wrap items-center justify-center xl:justify-start     gap-[16px]  max-w-[330px] md:max-w-[330px] xl:max-w-[560px]">
            <div className="tag-m xl:tag-d md:tag-t"> Marketplace</div>
            <div className="tag-m xl:tag-d md:tag-t"> Web </div>
            <div className="tag-m xl:tag-d md:tag-t"> Mobile App </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Fuplace;
