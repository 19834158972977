import React, { useEffect } from "react";
import "./modalFullScreenMobile.scss";

// interface ModalFullScreenMobileProps {
//   children?: ReactNode;
//   showModal?: boolean;
//   overlay?: boolean;
//   clickChild?(): void;
//   handleCloseModal?(): void;
// }

export const ModalFullScreenMobile = ({
  clickChild,
  children,
  showModal,
  overlay,
  handleCloseModal,
}) => {
  useEffect(() => {
    let element = document.querySelector("body");
    showModal
      ? element?.classList.add("rhOverflow")
      : element?.classList.remove("rhOverflow");
  }, [showModal]);

  const handleClickChild = (e) => {
    e.stopPropagation();
    clickChild && clickChild();
  };

  const handleClickParentCloseModal = () => {
    let element = document.querySelector("body");
    element?.classList.remove("rhOverflow");
    handleCloseModal();
  };

  return (
    <div
      className={`modalFullScreenMobile ${showModal ? "showModal" : ""} `}
      onClick={handleClickParentCloseModal}
    >
      <div onClick={handleClickChild}>{children}</div>
    </div>
  );
};
