import { AI, DESIGN, VR, BLOCKCHAIN, DEVELOPMENT, SUPPORT } from "../../asset";
import Smiley from "../../asset/smiley.svg";

export const data = [
  {
    icon: (
      <DEVELOPMENT className="  xl:w-[100px] xl:h-[85px] w-[48px] h-[41px] " />
    ),
    title: "Development",
    description:
      // "We have our own strategy for each project. All our code is clean, and we specialise in making our programming results fast and lightweight. Do you see our website? Exactly the same.",
      "Our Development team have great technical expertise and problem-solving skills, we pay attention to even the smallest of details while working collaboratively with our clients. We are adaptable as we are aware that technology is constantly evolving. And, most importantly we have a passion for growth and learning."
  },
  {
    icon: <DESIGN className="xl:w-[88px] xl:h-[85px] w-[43px] h-[41px] " />,
    title: "Design",
    description:
      // "User is the sun of our design system. The principle of our designs is based on creative deconstruction. We know very well how to use the user interface and user experience to give birth to pure ideas.",
      "Great User Experience (UX) and User Interface (UI) design are about creating intuitive, efficient, and enjoyable digital experiences for users. Our designers work together to create a seamless and satisfying product that makes users feel controlled and confident in their actions."
  },
  {
    icon: (
      <BLOCKCHAIN className="xl:w-[100px] xl:h-[85px]  w-[49px] h-[41px] " />
    ),
    title: "Blockchain",
    description:
      // "Over Using your own approach, we will transform your business to keep up with the future. If ever, Using blockchain capabilities to create transparency, Save time, Reduce costs and make your business more efficient you haven't used, We are here!",
      "Our team has extensive experience in creating decentralised systems using cutting-edge technologies and tools. Whether it’s developing a custom blockchain solution, implementing smart contracts, or building decentralised applications, we have the skills and knowledge to deliver innovative and secure products."
  },
  {
    icon: <AI className="xl:w-[72px] xl:h-[85px] w-[35px] h-[41px] " />,
    //title: "AI",
    // title: "Artificial Intelligence (AI)",
    title: "Artificial Intelligence",
    description:
      // "At Raadco, we believe that artificial intelligence can create a new meaning in human life. Improving people's lives using artificial intelligence. For us, it is more than a business matter.",
      "We create custom AI solutions that help businesses automate processes, improve decision-making, and gain insights from data whether it’s building machine learning models, computer vision systems, or natural language processing applications."      
  },
  {
    icon: <VR className="xl:w-[93px] xl:h-[85px] w-[45px] h-[41px] " />,
    //title: "VR",
    // title: "Virtual Reality (VR)",
    title: "Virtual Reality",
    description:
      // "Since we have seen cars moving by themselves or watched the movie, Ready Player One, our minds have been busy. Do you have a virtual reality project to discuss?",
      // "We create immersive VR experiences that engage users and deliver impactful results. Whether it’s building VR games, training simulations, or marketing experiences, we have the skills and knowledge to deliver innovative and high-quality VR products. We use the latest VR technology and tools to create interactive and captivating VR environments that provide a unique and memorable experience."
      // "Our focus is on creating immersive VR experiences that engage users and deliver impactful results. Our team has the skills and knowledge to build innovative and high-quality VR games, training simulations, and marketing experiences. Utilizing the latest VR technology and tools, we create interactive and captivating environments that offer a unique and memorable experience for users."
      "We specialise in VR experiences that engage and deliver impactful results. We build innovative VR games, training, and marketing experiences. With the latest VR technology, we create captivating environments for a unique and memorable experience."
      
  },

  {
    icon: <SUPPORT className="xl:w-[111px] xl:h-[85px] w-[54px] h-[41px] " />,
    // title: `Happiness  <img  alt="smiley"   class=" md:w-[30px] md:h-[30px]     w-[25px] h-[25px] xl:w-[49px] xl:h-[50px] " src=${Smiley} alt=""/>`,
    title : "Fun",
    description:
      // "A little fun and modernity for every project of your business growth, That's the subtlety you need. This is not an official specialty, But we know its importance. In every project, We are very responsible for it.",
      "\“If you’re not having fun, you’re doing something wrong.\” - Geddy Lee, Canadian musician and songwriter.\nSure we go to work every day but, we believe whether it’s work or other areas of life, finding joy and fulfilment is essential to overall well-being and happiness."
  },
];
