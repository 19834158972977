import React from "react";

import Bounty from "../../asset/Bountye.svg";
import Humm from "../../asset/humm.svg";
import Fuplace from "../../asset/fuplace.svg";
import Express from "../../asset/express.svg";

import { BelzLogo } from "../../asset/belz.js";

const Clients = () => {
  return (
    <section id="client" className="">
      <div className="flex flex-col items-center justify-center">
        <h4 className="  xl:mt-[202px] lg:mt-[101px]   md:mt-[104px]  mt-[91px]    font-bold  text-light text-[12px]  leading-[33px] text-center ">
          clients’ projects
        </h4>
        <h1 className=" xl:max-w-[1184px] max-w-[374px]  xl:mt-[24px] xl:mb-[37px] lg:mt-[24px] lg:mb-[37px] md:mt-[8px] md:mb-[32px] my-[24px] lg:max-w-[620px] md:max-w-[548px]    feat-head-m md:feat-head-t xl:feat-head-d ">
          Some of our <span className="text-[#433CAE]">clients’</span> projects
        </h1>
      </div>

      <div className=" mb-[48px] items-center lg:mt-[38px]  xl:max-w-[1044px]   max-w-[271px] gap-[17px]  flex justify-center flex-wrap mx-auto lg:gap-[56px] xl:gap-[56px] md:gap-[35px]  md:mb-[46px]  xl:mb-[144px] lg:mb-[75px] lg:max-w-[620px] md:max-w-[392px]    ">
        <img
          src={Bounty}
          alt="bounty"
          className="md:w-[98px]  md:h-[30px]  w-[75px] h-[23px]  lg:w-[165px] lg:h-[49px]   "
        />
        <img
          src={Humm}
          alt="humm"
          className="md:w-[116px]  md:h-[26px]  lg:w-[170px] lg:h-[34px]  w-[76px] h-[17px]  "
        />
        <img
          src={Fuplace}
          alt="fuplace"
          className="md:w-[108px]  md:h-[32px] lg:w-[164px] lg:h-[50px]   w-[86px] h-[26px]   "
        />

        <img
          src={Express}
          alt="american express"
          className="md:w-[84px]  md:h-[27px] lg:w-[122px] lg:h-[41px]     w-[63px] h-[21px]   "
        />

        <BelzLogo
          className="md:w-[161px]  md:h-[48px]  lg:w-[199px] lg:h-[50px]   w-[98px] h-[29px]   "
          color={"#D1D5DB"}
        />
      </div>
    </section>
  );
};

export default Clients;
