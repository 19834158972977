import React from "react";

import catalyser from "../../asset/catalyser.png";

import catalyserLogo from "../../asset/catalyserLogo.svg";
import catalyserBgLine from "../../asset/catalyserSvgLine.svg";

import "./style.css";

const Catalyser = () => {
  const badges = [
    "Marketplace",
    "SEO",
    "Mobile App ",
    "Bills",
    "Dashboard",
    "Gift-cards",
    "Azure",
  ];
  return (
    <section
      id="catalyser"
      className="catalyserSvgLine-bg  text-[#1F4653] relative lg:mb-[33px] xl:mb-[64px]  lg:max-w-[960px]  bg-[#F3E2D2] flex items-center overflow-hidden   xl:bg-height  xl:flex-row xl:gap-0 lg:gap-[80px] md:gap-[70px]
      sm:gap-[40px]  flex-col justify-center  xl:max-w-[1424px] mx-auto   "
    >
      <div className="xl:pl-[86px] lg:pl-0 flex relative  space-y-[43px]    flex-col items-center justify-around  xl:items-start xl:justify-around  xl:max-w-[1302px] mx-auto     max-h-[546px]  ">
        <img
          src={catalyserLogo}
          className="  mt-[80px] xl:mt-0    w-[197px] h-[56px]   lg:w-[256px] lg:h-[72px] md:w-[235px] md:h-[67px]   xl:w-[256px] xl:h-[72px] "
          alt="humm-logo"
        />

        <h3
          className="xl:max-w-[546px] lg:max-w-[847px] md:max-w-[594px] max-w-[353px]
    xl:wear-desc-d md:wear-desc-t wear-desc-m xl:text-left !text-[#1F4653]"
        >
          Catalyser is the easy to use giving platform that gives you full
          control over your corporate social responsibility program.
        </h3>

        <div className="flex md:max-w-[490px]   flex-wrap lg:max-w-[490px] items-center justify-center xl:justify-start gap-y-[28px]    gap-x-[16px]  max-w-[330px] xl:max-w-[560px]">
          {badges.map((item, index) => (
            <span
              key={index}
              className="tag-m xl:tag-d md:tag-t !text-white !bg-[#3F7C85]"
            >
              {item}
            </span>
          ))}
        </div>
      </div>

      <div className="flex">
        <img
          src={catalyser}
          alt="background"
          className="z-10 w-auto h-auto max-h-[765px]  "
        />
      </div>
    </section>
  );
};

export default Catalyser;
