import React from "react";
import "./App.css";
import "swiper/css";
import "swiper/css/effect-fade";
import "swiper/css/navigation";
import "swiper/css/pagination";
import { Route, Routes } from "react-router";
import Header from "./components/Header";
import Footer from "./components/Footer";

import Home from "./pages/HomePage";

function App() {
  return (
    <div id="app">
      <Header />
      <Routes>
        <Route path="/" element={<Home />} />
      </Routes>
      <Footer />
    </div>
  );
}

export default App;
