import React from "react";
import Card from "../Card";
import { data } from "../Card/featureData";

const Feature = () => {
  return (
    <section
      id="specialty"
      className="flex flex-col items-center justify-center "
    >
      <h4 className="  xl:mt-[168px]  md:mt-[71.77px]  mt-[111px]    font-bold  text-light text-[12px]  leading-[33px] text-center ">
        Our specialty
      </h4>
      <h1
        className="
      max-w-[374px]
      mb-[40px]
      md:max-w-[548px]
      md:mb-[72px]
      lg:max-w-[636px]
      lg:mb-[32px]
      xl:max-w-[905px]
      xl:mb-[88px]







      feat-head-m md:feat-head-t xl:feat-head-d "
      >
        What fields do we <span className="text-[#433CAE]">specialise</span> in?
      </h1>
      <div className="max-w-[1424px]   grid  px-[8px]  md:mb-[64px]   md:px-[15px]  grid-cols-1  md:grid-cols-2     xl:grid-cols-3  gap-[16px]">
        {data?.map((item, index) => (
          <Card
            key={index}
            title={item.title}
            subTitle={item.description}
            icon={item.icon}
          />
        ))}
      </div>
    </section>
  );
};

export default Feature;
