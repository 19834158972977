import React from "react";
import "./card.css";

const Card = ({ title, subTitle, icon }) => {
  return (
    <div className="flex flex-col items-start p-[32px] justify-start border-[1px] border-[#D1D5DB]  ">
      {icon}
      <h2
        className="flex items-center gap-[10px] feat-title-m md:feat-title-t xl:feat-title-d "
        dangerouslySetInnerHTML={{ __html: `${title}` }}
      />

      <h3 className="feat-subtitle-m md:feat-subtitle-t xl:feat-subtitle-d ">
        {subTitle}
      </h3>
    </div>
  );
};

export default Card;
