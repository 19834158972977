import LogoIcon from "../../asset/logo-icon.svg";
import LogoText from "../../asset/logo-text.svg";
import { navigation, social } from "./data";
import Scroll from "../Scroll";
import { Link } from "react-router-dom";

export default function Footer() {
  return (
    <footer
      id="footer"
      className="bg-[#433CAE]   relative"
      aria-labelledby="footer-heading"
    >
      <h2 id="footer-heading" className="sr-only">
        Footer
      </h2>
      <div className="px-4 py-12 mx-auto max-w-7xl sm:px-6 lg:py-32 lg:px-8">
        <div className="     xl:flex  xl:justify-between xl:gap-x-[240px]">
          <div className=" flex flex-col items-center  xl:items-start     space-y-[16px] md:min-w-[360px]   xl:col-span-1">
            <Link
              to="/"
              className="flex     xl:h-[40px]  flex-row    items-center flex-nowrap    gap-x-[6px]"
            >
              <img className=" nonselect" src={LogoIcon} alt="raadco-logo" />
              <img className=" nonselect" src={LogoText} alt="raadco-logo" />
            </Link>
            <p className=" flex  xl:text-[16px]  md:text-[20px]   foot-txt  max-w-[360px] lg:text-[22px]   lg:max-w-[360px] text-center  xl:text-left   ">
            We collaborate with startups to enterprises, producing user-friendly products
            </p>
            <div className="flex flex-col text-center   space-y-[8px]  lg:pt-[46px]   ">
              {navigation.contact.map((item) => (
                <div key={item.text}>
                  <div className="flex  justify-center  xl:justify-start   items-start gap-x-[14px] ">
                    <item.icon
                      className="w-[24px] h-[24px]"
                      aria-hidden="true"
                    />
                    <p className="foot-txt md:text-[20px]    lg:text-[22px]  xl:text-[16px]    ">
                      {item.text}
                    </p>
                  </div>
                </div>
              ))}
              <div className=" hidden   xl:pt-[74px] w-[560px]  xl:flex justify-center  xl:justify-start  items-center gap-[8px] ">
                <p className="foot-txt text-center flex  items-start   sm:items-center gap-[8px]">
                  <span className="text-[#32B3E0]  text-[26px]  ">&copy;</span>{" "}
                  {new Date().getFullYear()} Raadco, PTY.LTD. All Rights
                  Reserved.
                </p>
              </div>
            </div>
            <div className="flex space-x-6 text-center    xl:hidden   py-[56px] ">
              {social.map((item) => (
                <a
                  target="_blank"
                  rel="noreferrer"
                  key={item.name}
                  href={item.href}
                  className="text-center text-white hover:text-[#32B3E0]"
                >
                  <span className="sr-only">{item.name}</span>
                  <item.icon
                    className="w-6 h-6 text-center"
                    aria-hidden="true"
                  />
                </a>
              ))}
            </div>
          </div>
          <div className="hidden grid-cols-3 mt-12 gap-x-[360px]    break-keep xl:grid xl:mt-0">
            <div className="min-w-[150px]">
              <div>
                <h3 className="foot-title mb-[16px] mt-[4px]  ">Our services</h3>
                <ul className="space-y-[8px]    ">
                  {navigation.Ourservice.map((item) => (
                    <li key={item.name}>
                      <a
                        href={item.href}
                        className="foot-link   hover:text-[#32B3E0] "
                      >
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div>
            <div className="min-w-[224px] mt-12 md:mt-0 ">
              <h3 className="foot-title mb-[16px] mt-[4px] ">Our works</h3>
              <ul className="space-y-[8px]">
                {navigation.Ourworks.map((item) => (
                  <li key={item.name}>
                    <a
                      href={item.href}
                      className="foot-link    hover:text-[#32B3E0]  "
                    >
                      {item.name}
                    </a>
                  </li>
                ))}
                <div className="flex space-x-6 text-center">
                  {social.map((item) => (
                    <a
                      target="_blank"
                      rel="noreferrer"
                      key={item.name}
                      href={item.href}
                      className="mt-[36px] text-center text-white hover:text-[#32B3E0]"
                    >
                      <span className="sr-only">{item.name}</span>
                      <item.icon
                        className="w-6 h-6 text-center "
                        aria-hidden="true"
                      />
                    </a>
                  ))}
                </div>
              </ul>
            </div>

            {/* <div className="">
              <div>
                <h3 className="foot-title mb-[16px] mt-[4px] ">Company</h3>
                <ul className="space-y-[8px]">
                  {navigation.company.map((item) => (
                    <li key={item.name}>
                      <a href={item.href} className="foot-link">
                        {item.name}
                      </a>
                    </li>
                  ))}
                </ul>
              </div>
            </div> */}
          </div>
        </div>
        <div className="xl:pt-[74px] xl:hidden  flex justify-center  xl:justify-start  items-center gap-[8px] ">
          <p className="foot-txt text-center flex  items-start   sm:items-center gap-[8px]">
            <span className="text-[#32B3E0]  text-[26px]  ">&copy;</span>{" "}
            {new Date().getFullYear()} Raadco, PTY.LTD. All Rights Reserved.
          </p>
        </div>
      </div>

      <div className="absolute hidden xl:block   right-[58px] bottom-[87px] z-50 ">
        <Scroll />
      </div>
    </footer>
  );
}
