import React from "react";
import Exchange from "../../asset/exchange-logo.svg";
import Icert from "../../asset/icert-logo.svg";
import Raadcoin from "../../asset/raadcoin-logo.svg";
import Wear from "../../asset/wear-logo.svg";
import Remocapp from "../../asset/remocapp.svg";
const Products = () => {
  return (
    <section
      id="products"
      className="bg-[#221E57] flex flex-col items-center justify-center  "
    >
      <h4 className="font-bold text-[12px] leading-[33px] text-[#00E4FF] mt-[48px]  xl:mt-[162px]  ">
        Our products
      </h4>
      <h1 className="xl:pro-head-d pro-head-m md:pro-head-t mt-[24px] md:mt-[32px] xl:mt-[24px]   ">
        We work on <span className="text-[#00E4FF]">High-Tech </span>projects
      </h1>

      <div className="flex flex-wrap items-center justify-center max-w-[304px] gap-[34px] mb-[88px] md:mb-[77px] xl:mb-[204px]   md:max-w-[704px] xl:max-w-[1184px] ">
      <img
          src={Remocapp}
          alt="remocapp"
          className="w-[100px]  h-[19px] md:w-[150px]  md:h-[36px] xl:w-[183px]  xl:h-[38px]  "
        />
       
        <img
          src={Wear}
          alt="wearland"
          className="w-[48px]  h-[19px] md:w-[91px]  md:h-[36px] xl:w-[124px]  xl:h-[48px]  "
        />
        <img
          src={Exchange}
          alt="exchange-logo"
          className="w-[127px]  h-[13px] md:w-[281px]  md:h-[28px] xl:w-[331px]  xl:h-[37px]  "
        />
        <img
          src={Icert}
          alt="icert-logo"
          className="w-[61px]  h-[17px] md:w-[111px]  md:h-[34px] xl:w-[158px]  xl:h-[45px]    "
        />
        <img
          src={Raadcoin}
          alt="raadcoin-logo"
          className="w-[70px]  h-[21px] md:w-[119px]  md:h-[32px]  xl:w-[183px]  xl:h-[53px]  "
        />
      </div>
    </section>
  );
};

export default Products;
