import React from "react";

import ExpressLogo from "../../asset/AmexLogo.svg";
import ExpressImage from "../../asset/express-image.webp";

const Express = () => {
  return (
    <section
      id="amex"
      className="mx-auto   xl:bg-height  lg:max-w-[960px] xl:max-w-[1424px]  express-bg"
    >
      <div className=" flex xl:flex-row   flex-col   lg:mb-[33px] xl:mb-[64px]  items-center lg:max-w-[960px]       gap-y-[3vh]   xl:max-w-[1202px] mx-auto ">
        <div className="flex flex-col   mt-[10vh] xl:mt-0 gap-y-[2vh]    items-center justify-around  xl:items-start xl:justify-start  xl:max-w-[1302px] mx-auto    max-h-[546px]    ">
          <img
            src={ExpressLogo}
            className="w-[197px] h-[56px]  lg:w-[256px] lg:h-[72px] md:w-[235px] md:h-[67px]   xl:w-[230px] xl:h-[111px] "
            alt="american express"
          />

          <h2 className="xl:max-w-[546px]    lg:max-w-[547px] md:max-w-[594px] max-w-[353px]
                         xl:wear-desc-d md:wear-desc-t wear-desc-m xl:text-left">
                          <div>
            We implemented an affiliate cashback platform for Amex and ShinhanCard called
            <strong> MyAmexShopping </strong> which lets Korean users to give
            cashbacks rewards after they buy from multiple merchants.
            It has been integrated
            with tens of affiliate networks, and different currencies.
            </div>
          </h2>

          <div className="flex flex-wrap items-center justify-center xl:justify-start     gap-[16px]        sm:max-w-[400px]     xl:max-w-[400px]">
            <div className="tag-m xl:tag-d md:tag-t"> Web & Portal</div>
            <div className="tag-m xl:tag-d md:tag-t"> Dashboard </div>
            <div className="tag-m xl:tag-d md:tag-t"> Multi language </div>
            <div className="tag-m xl:tag-d md:tag-t"> Cashback </div>
            <div className="tag-m xl:tag-d md:tag-t"> Multi currency </div>
            <div className="tag-m xl:tag-d md:tag-t"> AWS </div>
          </div>
        </div>

        <div>
          <img
            src={ExpressImage}
            alt="background"
            className="xl:w-[718px] xl-h-[633px]  lg:my-[50px]   xl:my-[100px] "
          />
        </div>
      </div>
    </section>
  );
};

export default Express;
