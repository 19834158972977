import React from "react";
import remocappLogo from "../../asset/remocappLogo.svg";
import Mockup from "../../asset/ReMoCappPreview.png";
import './remocapp.css'
// import Shape from "../../asset/excShape-md.svg";

const Remocapp = () => {
  return (   

    <section id="remoc" className="min-h-screen remoc-bg ">
    <div className=" relative flex flex-col items-center justify-center min-h-screen remoc-bg-d xl:flex-row xl:justify-center lg:flex-col">
      <div className="  xl:px-16  z-10 flex flex-col items-center xl:flex-col xl:justify-start xl:items-start xl:text-left">
        <img
          src={remocappLogo}
          className=" p-1 px-10 sm:p-0 xl:mt-[116px] xl:mb-[60px]   md:mt-[72px] md:mb-[40px] xl:text-left  mt-[88px] mb-[60px]   lg:max-w-[436px] h-[36px]  md:max-w-[448px] md:h-[48px]   xl:max-w-[300px] xl:h-[62px] "
          alt="wearland"
        />

        <h2
          style={{ marginBottom: "40px" }}
          className="xl:max-w-[704px] sm:max-w-[704px] md:px-0 sm:px-5 xl:wear-desc-d md:wear-desc-t wear-desc-m xl:text-left "
        >
         ReMoCapp is a markerless AI based application that captures human motions and sends the realtime animation data to other 3D and Game development software, and AR/VR applications, without need to wear gloves and suits. It let you setup your free Mocap studio
        </h2>

        <div className="flex  flex-wrap mb-[32px]  md:mb-[90px]    xl:mb-[48px] items-center justify-center gap-[16px] sm:max-w-[287px]  lg:max-w-[500px] md:max-w-[330px] xl:max-w-[560px] ">
          <div className="tag-m xl:tag-d md:tag-t"> AI </div>
          <div className="tag-m xl:tag-d md:tag-t"> Metaverse </div>
          <div className="tag-m xl:tag-d md:tag-t"> Game engine </div>
          <div className="tag-m xl:tag-d md:tag-t"> VR </div>
          <div className="tag-m xl:tag-d md:tag-t"> 3D </div>
        </div>
      </div>

      <img src={Mockup}  className="  md:max-w-[667px] xl:max-w-[900px] lg:max-w-[1023px] sm:h-[244px]  xl:h-[576px] md:h-[376px] " alt="mockup"/>
    </div>
  </section>








//    <div className="re-bg ">
//     <section className="  bg-gradient-radial relative flex flex-col items-center justify-center min-h-screen overflow-hidden  xl:flex-row xl:justify-center ">

//  <div className="  z-10 flex flex-col items-center xl:flex-col xl:justify-start xl:items-start xl:text-left">
//         <img
//           src={remocappLogo}
//           className="xl:mt-[116px] xl:mb-[8px]   md:mt-[72px] md:mb-[40px]   mt-[88px] mb-[40px]    w-[436px] h-[36px]  md:w-[448px] md:h-[48px]   xl:w-[436px] xl:h-[62px] "
//           alt="remocapp Logo"
//         />

//         <h2
//           style={{ marginBottom: "40px" }}
//           className=" xl:max-w-[704px]   md:max-w-[704px]  xl:wear-desc-d md:wear-desc-t wear-desc-m xl:text-left "
//         >
//           ReMoCapp is a markerless AI based application that captures human motions and sends the realtime animation data to other 3D and Game development software, and AR/VR applications, without need to wear gloves and suits. It let you setup your free MoCap studio
//         </h2>

//         <div className="flex  flex-wrap mb-[32px]  md:mb-[90px]    xl:mb-[48px] items-center justify-center gap-[16px]  max-w-[330px] md:max-w-[330px] xl:max-w-[560px]  ">
//           <div className="tag-m xl:tag-d md:tag-t"> AI </div>
//           <div className="tag-m xl:tag-d md:tag-t"> Metaverse </div>
//           <div className="tag-m xl:tag-d md:tag-t"> Game engine </div>
//           <div className="tag-m xl:tag-d md:tag-t"> VR </div>
//           <div className="tag-m xl:tag-d md:tag-t"> 3D </div>
//         </div>
//       </div>
     
//       <img
//         src={Mockup}
//         className="sm:max-w-[432px]    md:max-w-[667px]  xl:max-w-[1023px] sm:h-[376px]  xl:h-[673px] md:h-[576px] "
//         alt="mockup"
//       />

         
//     </section> 
//      </div> 

  );
};

export default Remocapp;

