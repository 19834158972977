import React from "react";
import WearLogo from "../../asset/wear-logo.svg";
import WearLeft from "../../asset/wearleft.webp";
import WearRight from "../../asset/wearright.webp";
import Smile from "../../asset/shape.svg";
import "./wear.css";

const Wear = () => {
  return (
    <section
      id="wearland"
      className="relative flex flex-col items-center justify-center min-h-screen wear-bg"
    >
      <div className=" flex flex-col-reverse   xl:flex-row  xl:justify-end xl:gap-[27px] xl:items-center xl:flex xl:min-h-screen">
        <div className="xl:flex-1 xl:flex xl:justify-end xl:items-end ">
          <div className="flex flex-col justify-center items-center    mx-auto mt-[32px] mb-[51px] xl:mb-[24px] md:mb-[28px]   gap-[8px]  max-w-[335px]  md:max-w-[471px] xl:max-w-[580px]  ">
            <img src={WearLeft} className="z-10 w-auto h-auto " alt="mockup" />
            <img
              src={WearRight}
              className="z-10 w-auto h-auto "
              alt="mockuplogin"
            />
          </div>
        </div>
        <div className="flex flex-col items-center justify-center xl:flex-1 xl:flex-col xl:justify-start xl:items-start xl:text-left">
          <img
            src={WearLogo}
            className=" xl:mt-[116px] xl:mb-[8px]   md:mt-[72px] md:mb-[40px]   mt-[88px] mb-[40px]    w-[145px] h-[56px]  md:w-[166px] md:h-[64px]   xl:w-[236px] xl:h-[91px] "
            alt="wearland"
          />

          <img
            src={Smile}
            alt="smiley"
            className="absolute top-[248px] left-[-120px] md:top-[196px] md:left-[-200px] xl:top-[88px] xl:left-[-50px] "
          />
          <h2
            className="
          xl:max-w-[704px]
          md:max-w-[549px]
          max-w-[353px]
 xl:mb-[58px] mb-[24px]  xl:wear-desc-d md:wear-desc-t wear-desc-m xl:text-left "
          >
            This project is one of our ambitious ideas. In this project, we have
            tried to combine new advanced technologies and extract a remarkably
            efficient product from them. The idea of this project is to produce
            a fitting-room simulation platform that is entirely realistic. Not
            like the movies of 1995. The PoC phase of this project has been
            successfully completed, and we are producing early versions. This
            project is a large and long-term project that requires a lot of
            research and testing.
          </h2>

          <div className="flex  flex-wrap mb-[32px] md:mb-[66px] xl:mb-[48px] items-center justify-center gap-[16px]  max-w-[330px] md:max-w-[330px] xl:max-w-[560px]  ">
            <div className="tag-m xl:tag-d md:tag-t"> Web3 </div>
            <div className="tag-m xl:tag-d md:tag-t"> Metaverse </div>
            <div className="tag-m xl:tag-d md:tag-t"> Unreal engine </div>
            <div className="tag-m xl:tag-d md:tag-t"> VR </div>
            <div className="tag-m xl:tag-d md:tag-t"> AI </div>
            <div className="tag-m xl:tag-d md:tag-t"> Marketplace </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Wear;
