import React from "react";
// import Header from "../Header";

import HeroImage from "../../asset/hero.svg";
import "./hero.css";

const Hero = () => {
  return (
    <>
      <section
        id="hero"
        className="  mx-auto relative w-full  h-screen   flex flex-col max-w-[1920] items-center lg:min-h-[800px]  md:min-h-[680px]  xl:min-h-[880px]    sm:min-h-[600px]  test   shape bg-primary"
      >
        {/* <Header /> */}
        <div className="z-0 flex w-full hero-bg ">
          <img src={HeroImage} alt="hero " className="w-full nonselect" />
        </div>
        <h1 className="w-full  z-[1] sm:mt-[30vh]      lg:mt-[30vh]  xl:mt-[30vh]   lg:heading-lg    xl:heading-d heading-m md:heading-t">
          Building Better Solutions Together
          {/* <span className="text-white"> user-friendly </span>products */}
        </h1>

        <h2 className=" z-[1]   lg:max-w-[634px]    subheading-m xl:subheading-d md:subheading-t">
         We work collaboratively to deliver projects on time and on budget.
          {/* We will make sure that the products that are produced will cause your
          business to grow rapidly */}
        </h2>
      </section>
    </>
  );
};

export default Hero;
