import React from "react";
import IcertLogo from "../../asset/icert-logo.svg";
import certLarge from "../../asset/cert-large.webp";
import certMed from "../../asset/cert-med.webp";
import Gandom from "../../asset/gandom.svg";
import Laef from "../../asset/leaf.svg";

const Icert = () => {
  return (
    <section id="icert" className="relative min-h-screen cert-bg ">
      <div className="relative flex flex-col items-center justify-center min-h-screen mx-auto overflow-hidden xl:flex-row xl:justify-center cert-bg-d">
        <img
          className="  z-0  sm:top-[-200px] sm:right-[-50px]      absolute xl:top-0 xl:right-0 lg:w-[598px]  lg:top-[-300px] lg:right-[-100px]  sm:w-[259px]  md:right-[-60px]     "
          src={Gandom}
          alt="background"
        />
        <img src={Laef} alt="leaf" className="absolute bottom-0 left-0 z-0 " />
        <div className="">
          <div className="flex flex-col xl:px-[20px] items-center justify-center xl:justify-start xl:items-start xl:text-left">
            <img
              src={IcertLogo}
              className=" xl:mt-[82px] xl:mb-[8px] lg:mt-[119px] lg:mb-[8px]   md:mt-[72px] md:mb-[40px]   mt-[88px] mb-[40px]    w-[197px] h-[56px]  lg:w-[256px] lg:h-[72px] md:w-[235px] md:h-[67px]   xl:w-[256px] xl:h-[72px] "
              alt="icert-logo"
            />

            <h2
              className="xl:max-w-[847px] lg:max-w-[847px]
            md:max-w-[582px]
            max-w-[353px]
           mb-[24px]  lg:mb-[30px]    xl:mb-[30px] xl:wear-desc-d md:wear-desc-t wear-desc-m xl:text-left"
            >
              This project is one of our newest projects that will be launched
              in the first half of 2023. It is a complete solution for
              organisations to convert their Badges and Certificates into NFTs
              and assign their members.
            </h2>

            <div className="flex  flex-wrap mb-[32px] md:mb-[66px] xl:mb-[48px] items-center justify-center gap-[16px]  max-w-[330px] md:max-w-[330px] xl:max-w-[560px]  ">
              <div className="tag-m xl:tag-d md:tag-t"> Web3 </div>
              <div className="tag-m xl:tag-d md:tag-t"> Exchange </div>
              <div className="tag-m xl:tag-d md:tag-t"> SDK </div>
            </div>
          </div>

          <div className="  flex items-center justify-center  xl:mt-[-90px]   lg:mt-[70px]  ">
            <div className="flex flex-col-reverse items-center xl:flex xl:flex-row xl:items-end ">
              <img
                src={certMed}
                className="z-10   flex   w-[300px] h-[200px]   xl:w-[704px] xl:h-[483px]   lg:w-[490px] lg:h-[336px] md:max-w-[371px] md:max-h-[254px]  xl:max-w-[680px]  xl:max-h-[482px]  "
                alt="mockuplogin"
              />
              <img
                src={certLarge}
                className=" z-10 flex transform -rotate-3 lg:rotate-0 xl:rotate-0  w-[336px] h-[230px]   xl:w-[850px]  xl:h-[600px]  lg:w-[609px] lg:h-[418px] md:max-w-[477px] md:max-h-[327px] xl:max-w-[874px]  xl:max-h-[600px]     "
                alt="mockup"
              />
            </div>
          </div>
        </div>
      </div>
    </section>
  );
};

export default Icert;
