import React from "react";

import IcertLogo from "../../asset/raadcoin-logo.svg";
import Studio from "../../asset/Studio.webp";
import Shape from "../../asset/raadcoin.svg";

const Raddcoin = () => {
  return (
    <section
      id="raadcoin"
      className="relative flex flex-col items-center w-full min-h-screen raad-bg "
    >
      <img
        src={Shape}
        alt="shape"
        className="absolute w-full sm:bottom-0 md:top-0 "
      />
      <div className="flex flex-col items-center justify-center ">
        <img
          src={IcertLogo}
          className=" xl:mt-[4vh] xl:mb-[8px] lg:mt-[119px] lg:mb-[8px]   md:mt-[72px] md:mb-[40px]   mt-[88px] mb-[40px]     w-[197px] h-[56px]  lg:w-[256px] lg:h-[72px] md:w-[235px] md:h-[67px]   xl:w-[256px] xl:h-[72px] "
          alt="icert-logo"
        />

        <h2
          className="xl:max-w-[847px] lg:max-w-[847px] md:max-w-[594px] max-w-[353px]
 mb-[24px]  lg:mb-[30px]    xl:mb-[8px] xl:wear-desc-d md:wear-desc-t wear-desc-m xl:text-center"
        >
          We designed a decentralised portal for the internal management of our
          own company, which uses blockchain technology to handle the affairs of
          the accounting and HR unit. You can pay the salaries using
          cryptocurrencies and sign contacts with wallets. An NFT will be
          generated for each contract too.
        </h2>

        <div className="flex  flex-wrap mb-[32px] md:mb-[66px] xl:mb-[48px] items-center justify-center gap-[16px]  max-w-[330px] md:max-w-[330px] xl:max-w-[560px]  ">
          <div className="tag-m xl:tag-d md:tag-t"> Web3 </div>
          <div className="tag-m xl:tag-d md:tag-t"> ERP </div>
          <div className="tag-m xl:tag-d md:tag-t"> Accounting </div>
          <div className="tag-m xl:tag-d md:tag-t"> NFT </div>
        </div>
      </div>
      <div className="bottom-0 flex items-center justify-center mx-auto xl:absolute">
        <img
          src={Studio}
          alt="raadcoin"
          className="w-auto h-auto   xl:h-[50vh] px-2"
        />
      </div>
    </section>
  );
};

export default Raddcoin;
