import React from "react";
import SafeTDeviceLogo from "../../asset/SafeTDeviceLogo.svg";
import WearLeft from "../../asset/wearleft.webp";
import SafeTDeviceLeft from "../../asset/SafeTDevice.png";
import Smile from "../../asset/shape.svg";
import "./style.css";

const SafeTDevice = () => {
  const badges = ["IOT", "Online messaging ", "Dashboard", "Mobile app", "AWS"];
  return (
    <section
      id="SafeTDevice"
      className="xl:pt-[105px] lg:mb-[33px] xl:mb-[64px] sm:px-[22px] lg:max-w-[960px]
      xl:max-w-[1424px] mx-auto  lg:pb-[81px] lg:pt-[110px]  md:pt-[72.5px] md:pb-[91px] sm:pt-[88px] sm:pb-[76px] flex flex-col items-center justify-center min-h- bg-[#2C2C54] safe-T-Device-bg"
    >
      <div className=" flex flex-col-reverse   xl:flex-row  xl:justify-end sm:gap-[99px] xl:gap-[27px] lg:gap-[147px] md:gap-[165px] xl:items-center xl:flex xl:min-h-">
        <div className="xl:flex-1 xl:flex xl:justify-end xl:items-end ">
          <div className="flex flex-col justify-center items-center    mx-auto    gap-[8px]   ">
            <img
              src={SafeTDeviceLeft}
              className="z-10 w-auto h-auto "
              alt="Safe-T-Device-Sample"
            />
          </div>
        </div>
        <div className="flex-1 text-white">
          <div className="flex items-start xl:justify-start justify-center gap-[20.37px]">
            <img src={SafeTDeviceLogo} className="md:w-auto md:h-auto sm:w-[59px] sm:h-[59px] " alt="Safe-T-Device-Logo" />
            <div>
              <h2 className="text-white sm:text-[36.47px] md:text-[3.1855rem] font-bold">
                Safe-T Device
              </h2>
              <p className="md:text-[1.24719rem] text-white sm:text-[0.91194rem] ">BY IQM SYSTEMS</p>
            </div>
          </div>
          <div className="mt-[31px] flex xl:justify-start justify-center">
            <p className="font-medium md:text-[21px] xl:text-left text-center sm:text-[14px] sm:max-w-[346px] md:max-w-[546px]">
              IQM manufactures IoT technology to monitor and interpret data, 
              allowing our clients to manage their businesses safely,
              efficiently, and profitably. Our solutions detect and measure
              movement, pressure, visual information, overloading, and prevent
              tampering.
            </p>
          </div>
          <div className="flex  flex-wrap mb-[32px] items-center justify-center xl:justify-start md:gap-[16px] sm:gap-[28px]    mt-[22px]">
            {badges.map((item,index) => (
              <span key={index} className="tag-m xl:tag-d md:tag-t text-black  font-medium !bg-white">
                {item}
              </span>
            ))}
          </div>
        </div>
        {/* <div className="flex flex-col items-center justify-center xl:flex-1 xl:flex-col xl:justify-start xl:items-start xl:text-left">
          <div className="flex items-end">
            <img
              src={SafeTDeviceLogo}
              className=" xl:mt-[116px] xl:mb-[8px]   md:mt-[72px] md:mb-[40px]   mt-[88px] mb-[40px]    w-[145px] h-[56px]  md:w-[166px] md:h-[64px]   xl:w-[236px] xl:h-[91px] "
              alt="Safe-T-Device-Logo"
            />
            <h2 className="text-white text-[3.1855rem] font-bold">Safe-T Device</h2>
          </div>

          <h2
            className="
          xl:max-w-[704px]
          md:max-w-[549px]
          max-w-[353px]
 xl:mb-[58px] mb-[24px]  xl:wear-desc-d md:wear-desc-t wear-desc-m xl:text-left "
          >
            This project is one of our ambitious ideas. In this project, we have
            tried to combine new advanced technologies and extract a remarkably
            efficient product from them. The idea of this project is to produce
            a fitting-room simulation platform that is entirely realistic. Not
            like the movies of 1995. The PoC phase of this project has been
            successfully completed, and we are producing early versions. This
            project is a large and long-term project that requires a lot of
            research and testing.
          </h2>

          <div className="flex  flex-wrap mb-[32px] md:mb-[66px] xl:mb-[48px] items-center justify-center gap-[16px]  max-w-[330px] md:max-w-[330px] xl:max-w-[560px]  ">
            <div className="tag-m xl:tag-d md:tag-t"> Web3 </div>
            <div className="tag-m xl:tag-d md:tag-t"> Metaverse </div>
            <div className="tag-m xl:tag-d md:tag-t"> Unreal engine </div>
            <div className="tag-m xl:tag-d md:tag-t"> VR </div>
            <div className="tag-m xl:tag-d md:tag-t"> AI </div>
            <div className="tag-m xl:tag-d md:tag-t"> Marketplace </div>
          </div>
        </div> */}
      </div>
    </section>
  );
};

export default SafeTDevice;
