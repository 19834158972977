import React from "react";

import Shuffle from "../../asset/humm.webp";
import Bird from "../../asset/bird.svg";
import Left from "../../asset/hum-left.svg";
import "./humm.css";
import HummLogo from "../../asset/humm-white.svg";

const Humm = () => {
  return (
    <section
      id="humm"
      className=" relative lg:mb-[33px] xl:mb-[64px]  lg:max-w-[960px]  bg-[#FF6C01] flex items-center overflow-hidden   xl:bg-height  xl:flex-row  flex-col-reverse justify-center  xl:max-w-[1424px] mx-auto   "
    >
      <img
        src={Left}
        className="absolute bottom-0   md:w-full   md:h-full sm:object-cover opacity-50 blur-[3px]    sm:h-[800px] sm:w-[200px]     lg:h-[1376px]   left-0 z-0 mix-blend-screen "
        alt="light"
      />
      <img
        src={Bird}
        className="absolute bottom-0 right-0 z-0 sm:w-[167px]     md:w-[267px] xl:w-[400px]     "
        alt=" bird-icon"
      />

      <div className="flex">
        <img
          src={Shuffle}
          alt="background"
          className="z-10 w-auto h-auto max-h-[765px]  "
        />
      </div>

      <div className="flex relative  space-y-[30px]    flex-col items-center justify-around  xl:items-start xl:justify-around  xl:max-w-[1302px] mx-auto     max-h-[546px]  ">
        <img
          src={HummLogo}
          className="  mt-[80px] xl:mt-0   w-[197px] h-[56px]   lg:w-[256px] lg:h-[72px] md:w-[235px] md:h-[67px]   xl:w-[256px] xl:h-[72px] "
          alt="humm-logo"
        />

        <h2
          className="xl:max-w-[546px] lg:max-w-[847px] md:max-w-[594px] max-w-[353px]
    xl:wear-desc-d md:wear-desc-t wear-desc-m xl:text-left"
        >
          Hummgroup is a big BNPL company that has multiple products. We
          implemented 5 marketplaces for their products. All integrate with
          Bountye SaaS solution, with different frontend themes.
        </h2>

        <div className="flex md:max-w-[490px]   flex-wrap lg:max-w-[490px] items-center justify-center xl:justify-start gap-y-[28px]    gap-x-[16px]  max-w-[330px] xl:max-w-[560px]">
          <div className="tag-m xl:tag-d md:tag-t"> Marketplace</div>
          <div className="tag-m xl:tag-d md:tag-t"> SEO </div>
          <div className="tag-m xl:tag-d md:tag-t"> Mobile App </div>
          <div className="tag-m xl:tag-d md:tag-t"> Bills </div>
          <div className="tag-m xl:tag-d md:tag-t"> Dashboard </div>
          <div className="tag-m xl:tag-d md:tag-t"> Gift-cards </div>
          <div className="tag-m xl:tag-d md:tag-t"> Azure </div>
        </div>
      </div>
    </section>
  );
};

export default Humm;
